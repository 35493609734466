// Here you can add other styles
body {
  font-weight: bold;
}
.logo-print {
  height: 100px;
}
.border-dashed {
  border-style: dashed !important;
}
.card-header .col {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.pagination {
  cursor: pointer;
}

.pagination-sm {
  margin-bottom: 0;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 0 !important;
}
.is-invalid {
  outline: 1px red solid;
  border-radius: 4px;
}

.modal-header {
  .btn-close {
    margin: unset;
  }
}

.permission-grid {
  columns: auto 4;
  column-gap: 0;
  margin: 0;

  div.permission-card {
    break-inside: avoid;
    padding: 8px;
    page-break-inside: avoid;
    will-change: transform;
  }
  // @for $i from 1 through 36 {
  //   div.permission-card:nth-child(#{$i}) {
  //     $h: (random(400) + 100) + px;
  //     height: $h;
  //     line-height: $h;
  //   }
  // }
}
